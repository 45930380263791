import React from 'react';
import './Footer.scss';

function Footer() {
    return (
        <footer className='p-2 footer '>
            <div className='container p-0 d-flex justify-content-between align-items-center'>
                <p className='mb-0'>@2023</p>
                <p className='mb-0'>
                    contact:
                    <a href='mailto:contact@campaignforchange.tech' className='text-secondary ms-1'>
                        contact@campaignforchange.tech
                    </a>
                </p>
            </div>
        </footer>
    );
}

export default Footer;
