import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';
import './scss/Bootstrap.scss';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DummyBallet from './components/DummyBallot';
import Participants from './components/Participants';
import NavBar from './components/Navbar';
import Footer from './components/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));

const CommonLayout = ({ children }) => (
    <>
        <NavBar />
        {children}
        <Footer />
    </>
);


root.render(
    <BrowserRouter>
        <Routes>
            <Route
                index
                element={<CommonLayout><Participants /></CommonLayout>}
            />
            <Route
                path=':id'
                element={<CommonLayout><DummyBallet /></CommonLayout>}
            />
            <Route
                path='unauthorize'
                element={<CommonLayout><div>Unauthorize</div></CommonLayout>}
            />
        </Routes>
    </BrowserRouter>
);
