import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import participantsList from './data';

import './Participants.scss';
import { getSymbol, getSymbolName } from '../../utils';

function Participants() {
    const navigate = useNavigate();
    const [searchText, setSetsearchText] = useState('');

    const fileredParticipants = searchText
        ? participantsList.filter(p => {
              let isValid = false;
              if (
                  p.aliasName?.toLowerCase().includes(searchText) ||
                  p.name?.toLowerCase().includes(searchText) ||
                  p.constituency?.toLowerCase().includes(searchText) ||
                  p.symbolName?.toLowerCase().includes(searchText) ||
                  p.party?.toLowerCase().includes(searchText)
              ) {
                  isValid = true;
              }

              return isValid;
          })
        : participantsList;

    return (
        <div className='participants p-1 container'>
            <div className='input-group mb-2'>
                <input
                    type='text'
                    placeholder='Search for Candidate/Constituency'
                    value={searchText}
                    className='form-control'
                    aria-label='Amount (to the nearest dollar)'
                    onChange={e => setSetsearchText(e.target.value.toLowerCase())}
                />
                <span
                    className='input-group-text'
                    onClick={() => {
                        if (searchText) setSetsearchText('');
                    }}>
                    {searchText ? <i className='fa fa-times' /> : <i className='fa fa-search' />}
                </span>
            </div>

            {fileredParticipants.map(participant => {
                return (
                    <div className='participant m-1 rounded p-1 d-flex justify-content-between' key={participant.uniqueName} onClick={() => navigate(participant.uniqueName)}>
                        <div className='biodata d-flex align-items-center w-100'>
                            <img src={participant.photo} className='img-fluid rounded-circle' alt='Participant' width={100} />
                            <div className='d-flex w-100 flex-column justify-content-center align-items-center text-center ms-2 me-2'>
                                <p className='fw-bold text-center mb-1' style={{ fontSize: '12px' }}>
                                    {participant.name}
                                </p>
                                <p className='fw-bold mb-0' style={{ fontSize: '11px' }}>
                                    {participant.constituency}
                                </p>
                            </div>
                        </div>
                        <div className='symbol-details border d-flex flex-column justify-content-between align-items-center py-2'>
                            <img src={getSymbol(participant)} className='img-fluid rounded-circle' alt='Participant Symbol' width={100} />
                            <p className='mb-0'>{getSymbolName(participant)}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default Participants;
