import React, { useState, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import participantsList, { constituencies } from '../Participants/data';
import beepSound from '../../assets/beep.mp3';
import { getSymbol, getSymbolName } from '../../utils';
import './DummyBallot.scss';

const dummyParticipant = {
    slNo: '',
    name: '',
    symbol: '',
    photo: '',
};

function DummyBallot() {
    const { id } = useParams();

    const participant = useMemo(() => participantsList.find(p => p.uniqueName === id) || dummyParticipant, [id]);
    const participantConstituency = useMemo(() => constituencies[participant.constituency], [participant]);

    let counter = 0;
    const [ms, setMs] = useState(counter);

    const timerinterval = useRef(null);

    const timer = start => {
        if (start === true && counter >= 1) {
            timerinterval.current = setInterval(() => {
                setMs(counter);
                counter += 1;
                if (counter === 2) {
                    const beep = new Audio(beepSound);
                    beep.play();
                }
            }, [1000]);
        } else {
            setMs(0);
        }
    };

    const pressingDown = e => {
        counter = 1;
        timer(true);
    };

    const notPressingDown = e => {
        e.preventDefault();
        timer(false);
        setMs(0);
        clearInterval(timerinterval.current);
    };

    return (
        <div className='container p-0'>
            <div className='participant-details p-2 mb-1 mt-1 d-flex justify-content-between align-items-center'>
                <img src={participant.photo} className='img-fluid rounded-circle' alt='Participant' width={120} />
                <div className='d-flex flex-column justify-content-between align-items-end'>
                    <p>తెలంగాణ శాసనసభ ఎన్నికలు 2023</p>
                    <p className='mb-1'>
                        {`${participantConstituency.boothNumber} -`} {participant.constituency} నియోజకవర్గం{' '}
                    </p>
                    <p className='fw-bold'>{getSymbolName(participant)} గుర్తుకే మన ఓటు!</p>

                    <p className='mb-0' style={{ fontSize: '12px' }}>
                        పొలింగ్ తేది: <b>30-11-2023</b> గురువారము
                    </p>
                    <p className='mb-0' style={{ fontSize: '12px' }}>
                        సమయం: ఉ. గం" <b>7:00</b> నుండి సా గం" <b>5:00</b> వరకు{' '}
                    </p>
                </div>
            </div>
            <div className='dummy-ballot-wrapper'>
                <div className='dummy-ballot shadow-lg'>
                    <div className='dummy-decoration dec-1'></div>
                    <div className='dummy-decoration dec-2'></div>
                    <table className='table table-bordered border-secondary mt-4'>
                        <thead>
                            <tr>
                                <th scope='col'>Sl.No</th>
                                <th scope='col'>Name of Candidate</th>
                                <th scope='col'>Symbol</th>
                                <th scope='col' className='ballot-btn-col '>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <p className='fw-bold mb-0' style={{ fontSize: '8px' }}>
                                            Ballot Unit
                                        </p>
                                        <span className='signlal-light d-block bg-success'></span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from({ length: participantConstituency.participantsCount + 1 }, (v, i) => i)
                                .slice(1)
                                .map(i => {
                                    const p = i === participant.slNo ? participant : dummyParticipant;
                                    const correctParticipant = i === participant.slNo;
                                    const isPressing = correctParticipant && ms;
                                    const symbol = getSymbol(p);

                                    return (
                                        <tr key={i} className={`${correctParticipant && 'participant'}`}>
                                            <td>{i}</td>
                                            <td>
                                                <p style={{ fontSize: '12px' }} className='mb-0'>
                                                    {p.name} {p.aliasName && `@${p.aliasName}`}
                                                </p>
                                            </td>

                                            <td>{symbol && <img src={symbol} className='img-fluid rounded-circle' alt='Participant Symbol' width={100} />}</td>

                                            <td className='ballot-btn-col py-1'>
                                                <div className=' d-flex justify-content-between align-items-center'>
                                                    <span className={`signlal-light ${isPressing && 'bg-danger'}`}></span>
                                                    <div
                                                        className={`ballot-btn rounded-pill d-flex ms-2 ${isPressing && 'shadow-lg pressing'}`}
                                                        {...(correctParticipant && { onMouseDown: pressingDown, onMouseUp: notPressingDown, onTouchStart: pressingDown, onTouchEnd: notPressingDown })}>
                                                        <div className='w-100 rounded-pill'></div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>

                    <div style={{ fontSize: '8px' }} className='d-flex justify-content-between align-items-center mt-1 mx-1'>
                        <p className='mb-0'>
                            <i className='fa fa-check text-success'></i>&nbsp; Vote for a change&emsp;
                            <i className='fa fa-ban text-danger'></i>&nbsp; Don't vote for Money
                        </p>
                        <p className='mb-0'>Dummy Ballot</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DummyBallot;
